<template>
  <div>
    <b-row class="bg-white row-color">
      <b-col sm=0 md=1></b-col>
      <b-col sm=12 md=5 class="d-flex justify-content-center my-3">
        <a href="https://api.whatsapp.com/send?1=pt_br&phone=+5511997508061" class="mr-3" target="_blank">
          <i class="fab fa-whatsapp mr-2 mt-1"></i>
          <span> 11 99750-8061</span>
        </a>
        <a href="https://www.instagram.com/CartaoLegal/" target="_blank">
          <i class="fab fa-instagram mr-2 ml-2 mt-1"></i>
        </a>
        <a href="https://www.facebook.com/CartaoLegal/" target="_blank">
          <i class="fab fa-facebook mr-2 mt-1"></i>
        </a>
        <a href="https://www.linkedin.com/company/cartao-legal" target="_blank">
          <i class="fab fa-linkedin mr-2 mt-1"></i>
        </a>
        <span> @cartaolegal</span>
      </b-col>
      <b-col sm=12 md=5 class="d-flex justify-content-center my-3">
        <a href="mailto:atendimento@cartaolegal.com" class="mr-3" target="_blank">
          <i class="far fa-envelope mt-1 mr-2"></i>
          <span>atendimento@cartaolegal.com</span>
        </a>
        <a href="https://cartaolegal.com/ouvidoria" target="_blank">
          <i class="fas fa-headset mt-1 mr-2"></i>
          <span> Ouvidoria</span>
        </a>
      </b-col>
      <b-col sm=0 md=1></b-col>
    </b-row>
    <b-row style="background-color: #002e8a; height: 160px">
      <b-col sm=2></b-col>
      <b-col sm=8>
        <a href="https://cartaolegal.com/">
          <img src="logo-cartao-legal.png" class="my-3" alt="">
        </a>
      </b-col>
      <b-col sm=4></b-col>
    </b-row>
  </div>
</template>

<script>
export default {

}
</script>

<style>
  .row-color {
    color: #002e8a;
  }

  a {
    color: #002e8a;
    text-decoration: none
  }
</style>