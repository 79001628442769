<template>
  <div>
    <b-row class="responsive-box">
      <b-col sm=12 class="mb-2 mt-2">
        <b-row sm=12>
          <b-col sm=1><hr></b-col>
          <b-col sm=3 class="d-flex justify-content-center font-weight-bold mt-1">
            Informações Pessoais
          </b-col>
          <b-col sm=8><hr></b-col>
        </b-row>
      </b-col>
      <b-col sm=3>
        <span>CPF</span>
        <b-input type="text" v-model="data.cpf" id="cpf" maxlength="14" @keyup="CPFValidation(data.cpf), cpfMask(data.cpf, 'cpf')"></b-input>
      </b-col>

      <b-col sm=3>
        <span>RG</span>
        <b-input type="text" v-model="data.rg" id="rg" :disabled="!validCpf"></b-input>
      </b-col>
      <b-col sm=6></b-col>

      <b-col class="col-sm-9 mt-2">
        <span>Nome completo</span>
        <b-input type="text" v-model="data.name" id="name" :disabled="!validCpf"></b-input>
      </b-col>

      <b-col sm=12 class="mb-2 mt-3">
        <b-row sm=12>
          <b-col sm=1><hr></b-col>
          <b-col sm=3 class="d-flex justify-content-center font-weight-bold mt-1">
            Informações de Contato
          </b-col>
          <b-col sm=8><hr></b-col>
        </b-row>
      </b-col>

      <b-col sm=6>
        <span>E-mail</span>
        <b-input type="email" v-model="data.email" id="email" :disabled="!validCpf"></b-input>
        <b-form-checkbox
          id="emailCheckbox"
          :disabled="!validCpf"
          v-model="emailCheckbox"
          @change="setNotHave('email', emailCheckbox)"
          unchecked-value="not_accepted"
        >
          Não possuo
        </b-form-checkbox>
      </b-col>

      <b-col sm=6>
        <span>Telefone residencial</span>
        <b-input 
          type="text" 
          v-model="data.residentialNumber" 
          @keyup="phoneMask(data.residentialNumber, 'residentialNumber')" 
          maxlength="12"
          id="residentialNumber" 
          :disabled="!validCpf">
        </b-input>
        <b-form-checkbox
          id="residentialNumberCheckbox"
          :disabled="!validCpf"
          v-model="residentialNumberCheckbox"
          @change="setNotHave('residentialNumber', residentialNumberCheckbox)"
          unchecked-value="not_accepted"
        >
          Não possuo
        </b-form-checkbox>
      </b-col>

      <b-col sm=6 class="mt-3">
        <span>Celular</span>
        <b-input type="text" 
          v-model="data.cellphoneNumber" 
          @keyup="cellphoneMask(data.cellphoneNumber, 'cellphoneNumber')" 
          maxlength="13"
          id="cellphoneNumber" 
          placeholder="(00) 00000-0000" 
          :disabled="!validCpf">
        </b-input>
        <b-form-checkbox
          id="cellphoneNumberCheckbox"
          :disabled="!validCpf"
          v-model="cellphoneNumberCheckbox"
          @change="setNotHave('cellphoneNumber', cellphoneNumberCheckbox)"
          unchecked-value="not_accepted"
        >
          Não possuo
        </b-form-checkbox>
        <b-form-checkbox
          id="whatsCheckbox"
          class="text-success"
          :disabled="!validCpf"
          @change="setWhatsappNumber()"
          v-model="whatsCheckboxSet"
          unchecked-value="not_accepted"
        >
          Este número é WhatsApp
        </b-form-checkbox>
      </b-col>

      <b-col sm=6 class="mt-3">
        <span>WhatsApp</span>
        <b-input 
          type="text" 
          v-model="data.whatsApp"
          @keyup="cellphoneMask(data.whatsApp, 'whatsApp')" 
          maxlength="13"
          id="whatsApp" 
          placeholder="(00) 00000-0000" 
          :disabled="!validCpf || data.whatsApp == data.cellphoneNumber && data.cellphoneNumber != ''">
        </b-input>
        <b-form-checkbox
          id="checkbox-3"
          :disabled="!validCpf"
          v-model="whatsappCheckbox"
          @change="setNotHave('whatsApp', whatsappCheckbox)"
          unchecked-value="not_accepted"
        >
          Não possuo
        </b-form-checkbox>
      </b-col>

      <b-col sm=12 class="mb-2 mt-3">
        <b-row sm=12>
          <b-col sm=1><hr></b-col>
          <b-col sm=3 class="d-flex justify-content-center font-weight-bold mt-1">
            Informações do cartão
          </b-col>
          <b-col sm=8><hr></b-col>
        </b-row>
      </b-col>

      <b-col sm=6>
        <span>Categoria do cartão</span>
        <b-form-select v-model="data.cardCategory" id="cardCategory" :options="cardOptions"></b-form-select>
      </b-col>

      <b-col sm=6 v-if="formType == 'cancelTicket'">
        <span>Motivo do cancelamento</span>
        <b-form-select v-model="data.reason" id="reason" :options="reasonOptions"></b-form-select>
      </b-col>

      <b-col sm=12 class="d-flex justify-content-center mt-3">
        <b-form-checkbox id="terms" @change="acceptTerms()"/>
        <TermsModal/>
      </b-col>

      <b-col sm=12 class="text-center mt-4">
        <span v-show="emptyFields" class="text-danger">Preencha todos os campos</span>
      </b-col>

      <b-col sm=12 class="text-center mx-3 ml-0 pl-0 pr-3">
        <b-alert variant="warning" :show="existingProtocol">
          Você já possui uma solicitação para cancelamento em andamento. Para fazer uma nova solicitação, troque a categoria do cartão que deseja bloquear. Em casos de dúvida, entre em contato conosco. Telefone: (11) 4122-8400
        </b-alert>
      </b-col>

      <b-col sm=12 class="d-flex justify-content-center mt-1" v-if="formType == 'cancelTicket'">
        <b-button class="button-color" :disabled="!terms" @click="submitForm()">Confirmar solicitação</b-button>
        <!-- <b-button class="button-color" :disabled="!terms" @click="submitForm()">Somente cancelamento</b-button>
        <div class="mx-3"></div>
        <b-button class="button-color" :disabled="!terms" @click="submitTicketForm()">Cancelar e solicitar 2ª Via</b-button> -->
      </b-col>

      <!-- <b-col sm=12 class="d-flex justify-content-center mt-1" v-if="formType == 'secondTicket'">
        <b-button class="button-color" :disabled="!terms" @click="submitTicketForm()">Solicitar 2ª Via</b-button>
      </b-col> -->
    </b-row>

  </div>
</template>

<script>
import TermsModal from './termsModal.vue'

export default {
  components: {
    TermsModal
  },
  props: {
    formType: {
      type: String
    }
  },
  data() {
    return {
      cardOptions: [
        {value: '', text: '-Selecione uma opção-'}
      ],
      reasonOptions: [
        {value: '', text: '-Selecione uma opção-'}
      ],
      emptyFields: false,
      terms: false,
      validCpf: false,
      whatsCheckboxSet: false,
      emailCheckbox: false,
      residentialNumberCheckbox: false,
      cellphoneNumberCheckbox: false,
      whatsappCheckbox: false,
      loadingClientData: false,
      clientNotFound: false,
      clientRawData: [],
      existingProtocol: false,
      data: {
        cpf: '',
        rg: '',
        name: '',
        email: '',
        residentialNumber: '',
        cellphoneNumber: '',
        whatsApp: '',
        cardCategory: '',
        reason: '',
      },
      terms: false,
    }
  },
  created() {
    this.$parent.$on('resetForm', this.resetForm)
  },
  mounted() {
    this.cardParams()
    this.reasonParams()
  },
  methods: {
    async cardParams() {
      await this.callApi('get', 'cardParams').then(response => {
        const result = response.data
        result.forEach(element => {
          this.cardOptions.push({value: element.CT_ID, text: element.CT_CATEGORIA})
        });
      }).catch((err) => {
        console.log(err)
      });
    },
    async reasonParams() {
      await this.callApi('get', 'reasonParams').then(response => {
        const result = response.data
        result.forEach(element => {
          this.reasonOptions.push({value: element.MT_ID, text: element.MT_MOTIVO})
        });
      }).catch((err) => {
        console.log(err)
      });
    },
    CPFValidation(strCPF) {
      if(strCPF.length == 14) {
        strCPF = strCPF.replace('.','')
        strCPF = strCPF.replace('.','')
        strCPF = strCPF.replace('-','')
        var Soma;
        var Resto;
        var i;
        Soma = 0;
        if (strCPF == "00000000000") {
          document.getElementById('cpf').classList.add('is-invalid');
          this.validCpf = false
          return
        }

        for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)) ){
          document.getElementById('cpf').classList.add('is-invalid');
          this.validCpf = false
          return
        }

        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11) ) ) {
          document.getElementById('cpf').classList.add('is-invalid');
          this.validCpf = false
          return
        }
        
        document.getElementById('cpf').classList.remove('is-invalid');
        document.getElementById('cpf').classList.add('is-valid');
        this.validCpf = true
        return
      }
    },
    setNotHave(id, value) {
      if(value == true) {
        document.getElementById(id).value = 'NAO POSSUO'
        document.getElementById(id).disabled = true
      } else {
        document.getElementById(id).value = ''
        document.getElementById(id).disabled = false
      }
    },
    setWhatsappNumber() {
      this.data.whatsApp = this.data.cellphoneNumber
    },
    phoneMask(value, id) {
      if(value.length == 2) {
        document.getElementById(id).value += ' ';
      }
      if(value.length == 7) {
        document.getElementById(id).value += '-';
      }
    },
    cellphoneMask(value, id) {
      if(value.length == 2) {
        document.getElementById(id).value += ' ';
      }
      if(value.length == 8) {
        document.getElementById(id).value += '-';
      }
    },
    cpfMask(value, id) {
      if(value.length == 3) {
        document.getElementById(id).value += '.';
      }
      if(value.length == 7) {
        document.getElementById(id).value += '.';
      }
      if(value.length == 11) {
        document.getElementById(id).value += '-';
      }
    },
    createPayload() {
      return {
        clientData: {
          name: this.data.name,
          cpf: this.convertCPF(this.data.cpf),
          rg: this.data.rg
        },
        contactData: {
          email: this.data.email,
          phone: this.data.residentialNumber,
          cellphone: this.data.cellphoneNumber,
          whats: this.data.whatsApp
        },
        requestData: {
          cardCategory: this.data.cardCategory,
          cancelReason: this.data.reason
        }
      }
    },
    async submitForm() {
      await this.checkExistingData()
      if(!this.existingProtocol) {
        if(this.validateFields()) {
          this.$emit('submitAction', this.createPayload())
        }
      }

    },
    async submitTicketForm() {
      await this.checkExistingData()
      if(!this.existingProtocol) {
        if(this.validateFields()) {
          this.$emit('submitTicketAction', this.createPayload())
        }
      }
    },
    convertCPF(strCPF) {
      strCPF = strCPF.replace('.','')
      strCPF = strCPF.replace('.','')
      strCPF = strCPF.replace('-','')
      return strCPF
    },
    async checkExistingData() {
      this.existingProtocol = false
      await this.callApi('post', 'checkExistingRequest', {cpf: this.convertCPF(this.data.cpf), category: this.data.cardCategory}).then(response => {
        const result = response.data
        if(result.length > 0) {
          this.existingProtocol = true
        }
      }).catch((err) => {
        console.log(err)
      });
    },
    validateFields() {
      var validation = true
      this.emptyFields = false
      for(var prop in this.data) {
        if(this.formType == 'secondTicket') {
          if(prop != 'reason'){
            document.getElementById(prop).classList.remove('is-invalid');
            if(this.data[prop] == '') {
              document.getElementById(prop).classList.add('is-invalid');
              validation = false
              this.emptyFields = true
            }
          }
        } else {
          document.getElementById(prop).classList.remove('is-invalid');
          if(this.data[prop] == '') {
            document.getElementById(prop).classList.add('is-invalid');
            validation = false
            this.emptyFields = true
          }
        }
      }

      return validation
    },
    acceptTerms() {
      this.terms = !this.terms
    },
    resetForm() {
      for(var prop in this.data) {
        this.data[prop] = ''
      }
      document.getElementById("terms").checked = false;
    }
  }
}
</script>

<style>
.button-color {
  background-color: #002e8a;
  border: none;
}
</style>