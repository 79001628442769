<template>
  <div>
    <b-button
      variant="link"
      class="p-0"
      v-b-modal.terms-modal>
        Li e concordo com os termos
    </b-button>
    <b-modal size="lg" id="terms-modal" hide-header hide-footer>
      <div>
        <h6>Termos</h6>
        <p>Ao confirmar os termos você concorda em fornecer seus dados pessoais afim de realizar a solicitação em questão.</p>
        <b-button class="float-right" @click="$bvModal.hide('terms-modal')">Ok</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>