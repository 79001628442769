P<template>
  <div>
    <b-modal size="lg" id="privacy-modal" hide-header hide-footer>
        <p>
          Usamos cookies para melhorar a sua experiência em nosso site. Ao continuar a navegação consideramos que você aceita utilização
          <span class="text-info" v-b-modal.terms-modal>Política de privacidade</span>
        </p>
        <b-button class="float-right" variant="primary" @click="$bvModal.hide('privacy-modal')">Entendi</b-button>
    </b-modal>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>