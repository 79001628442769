<template>
  <div>
    <!-- Success Modal -->
    <b-modal id="success-modal" hide-footer hide-header>
      <b-row>
        <b-col sm="4" class="d-flex justify-content-center p-4 border-right">
          <i class="fas fa-check-circle modal-icon text-success opacity"></i>
        </b-col>
        <b-col sm="8" class="p-3">
          <b-row class="pt-3">
            <b-col sm="12" class="d-flex justify-content-center mb-3">
              <h4>Dados salvos com sucesso</h4>
            </b-col>
            <b-col sm="12" class="d-flex justify-content-center">
            <b-button
                variant="primary"
                class="mr-2"
                @click="$bvModal.hide('success-modal')">
                  Ok
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Confirmation Modal -->
    <b-modal id="confirmation-modal" hide-footer hide-header>
      <b-row>
        <b-col sm="4" class="d-flex justify-content-center p-4 border-right">
          <i class="fas fa-question-circle modal-icon text-warning opacity"></i>
        </b-col>
        <b-col sm="8" class="p-3">
          <b-row class="pt-3">
            <b-col sm="12" class="d-flex justify-content-center text-center mb-3">
              <h4>Tem certeza que deseja realizar essa operação?</h4>
            </b-col>
            <b-col sm="12" class="d-flex justify-content-center">
              <b-button
                variant="secondary"
                class="mr-2"
                @click="$bvModal.hide('confirmation-modal')">
                  Não
              </b-button>
              <b-button
                variant="primary"
                @click="submit()">
                  Sim
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Second confirmation Modal -->
    <b-modal id="second-confirmation-modal" hide-footer hide-header>
      <b-row>
        <b-col sm="4" class="d-flex justify-content-center p-4 border-right">
          <i class="fas fa-question-circle modal-icon text-warning opacity"></i>
        </b-col>
        <b-col sm="8" class="p-3">
          <b-row class="pt-3">
            <b-col sm="12" class="d-flex justify-content-center text-center mb-3">
              <h4>Tem certeza que deseja realizar essa operação?</h4>
            </b-col>
            <b-col sm="12" class="d-flex justify-content-center">
              <b-button
                variant="secondary"
                class="mr-2"
                @click="$bvModal.hide('second-confirmation-modal')">
                  Não
              </b-button>
              <b-button
                variant="primary"
                @click="secondSubmit()">
                  Sim
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Error Modal -->
    <b-modal id="error-modal" hide-footer hide-header>
      <b-row>
        <b-col sm="4" class="d-flex justify-content-center p-4 border-right">
          <i class="fas fa-times-circle modal-icon text-danger pt-3 opacity"></i>
        </b-col>
        <b-col sm="8" class="p-3">
          <b-row class="pt-3">
            <b-col sm="12" class="d-flex justify-content-center text-center mb-3">
              <h4>Ocorreu um erro ao realizar a operação.</h4>
            </b-col>
            <b-col sm="12" class="d-flex justify-content-center mb-3">
              <h6>Se o erro persistir, contate o suporte.</h6>
            </b-col>
            <b-col sm="12" class="d-flex justify-content-center">
            <b-button
                variant="secondary"
                class="mr-2"
                @click="$bvModal.hide('error-modal')">
                  Ok
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Loading Modal -->
    <b-modal id="loading-modal" hide-footer hide-header no-close-on-backdrop>
      <b-row>
        <b-col sm="4" class="d-flex justify-content-center p-4 border-right">
          <b-spinner label="Spinning" class="loading-icon"></b-spinner>
        </b-col>
        <b-col sm="8" class="p-3">
          <b-row class="pt-3">
            <b-col sm="12" class="d-flex justify-content-center mb-3">
              <h4>Processando dados</h4>
            </b-col>
            <b-col sm="12" class="d-flex justify-content-center mb-3">
              <h6>Aguarde</h6>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    submit() {
      this.$emit('submit')
    },
    secondSubmit() {
      this.$emit('secondSubmit')
    }
  }
}
</script>

<style>
.modal-icon {
  font-size: 80px;
}
.loading-icon {
  width: 80px;
  height: 80px;
}
.opacity {
  opacity: 0.5;
}
</style>