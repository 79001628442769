<template>
  <div>
    <!-- Modal 2ª via -->
    <b-modal id="second-ticket-modal" size="lg" hide-footer>
      <template #modal-title>
        Pagamento 2ª via
      </template>
      <b-row class="text-center mb-3">
        <h4>Protocolo de Segunda via Nº {{ protocol.secondTicket }}</h4>
        <h6 v-if="type == 'cancelTicket'">Cancelamento Nº {{ protocol.cancelling }}</h6>
      </b-row>
      <b-row>
        <b-col sm=4 class="d-flex justify-content-center">
          <a rel='nofollow' href='https://www.qr-code-generator.com' border='0' style='cursor:default'><img src='https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Fcartaolegal.com%2F&chs=180x180&choe=UTF-8&chld=L|2' alt=''></a>
          <img src="logo_pix.png" class="logo-pix" alt="">
        </b-col>
        <b-col sm=8 class="text-align-justify">
          <h4>Instruções</h4>
          <p class="font-weight-bold text-success">Sua solicitação foi registrada com sucesso!</p>
          <p>Para para dar continuidade na solicitação de segunda via, é necessário pagar uma taxa (R$ 00,00) via PIX através do QR Code ao lado, caso deseje pagar em dinheiro, somente será possível solicitar a 2ª via em um de nossos postos de atendimento.</p>
          <p>Após realizar o pagamento, basta levar seu protocolo de atendimento, juntamente com seu documento de identidade (RG, CNH) em um de nossos postos de atendimento para a emissão do cartão.</p>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Modal Cancelamento -->
    <b-modal id="cancel-feedback-modal" size="lg" hide-footer>
      <b-row class="text-center mb-3">
        <h4>Protocolo de Atendimento Nº {{ protocol.cancelling }}</h4>
      </b-row>
      <b-row class="mb-2">
        <b-col sm=4 class="d-flex justify-content-center">
          <i class="fas fa-check-circle modal-icon text-success opacity"></i>
        </b-col>
        <b-col sm=8 class="text-align-justify pt-2">
          <!-- <h4>Instruções</h4> -->
          <p class="font-weight-bold text-success">Sua solicitação foi registrada com sucesso!</p>
          <p>Caso queira solicitar uma segunda via, compareça em um dos nossos postos de atendimento com documento, RG e CPF.</p>
          <p>Ponto de atendimento: </p>
          <p>Rodoviária João Setti: Rua Domingos João Ballotin, 80 - Centro. De segunda a sexta das 07h às 19h e aos sábados das 07h às 15h</p>
          <p>Terminal Urbano Teresa Suster: Est do Rio Acima. S/N - Riacho Grande. (fecha em horário de almoço) De segunda a sexta das 08h às 17h e aos sábados das 08h às 12h</p>
          <p>Nos mantemos a disposição, tenha um ótimo dia!</p>       
          
          <!-- <p>Para para dar continuidade na solicitação de segunda via, é necessário pagar uma taxa (R$ 00,00) via PIX através do QR Code ao lado, caso deseje pagar em dinheiro, somente será possível solicitar a 2ª via em um de nossos postos de atendimento.</p>
          <p>Após realizar o pagamento, basta levar seu protocolo de atendimento, juntamente com seu documento de identidade (RG, CNH) em um de nossos postos de atendimento para a emissão do cartão.</p> -->
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    protocol: {
      type: Object,
      default: 0
    },
    type: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
.logo-pix {
  position: absolute;
  top: 200px;
  width: 140px;
  height: 50px;
}
</style>